<template>
<div class="home">
  <my-swiper v-if="isPc"></my-swiper>
  <my-swiper-mobile v-else></my-swiper-mobile>
  <div class="bg-grey">
    <div class="w-1200">
<!--赛事-->
    <div  v-if="isPc">
      <h3 class="modules-title grey" style="margin-top: 50px;">
        WHAT'S ON<i>最新活动</i>
        <div class="change-btns">
          <i class="arrow el-icon el-icon-arrow-right" @click="changeNNList(true)"></i>
          <i class="arrow el-icon el-icon-arrow-left" @click="changeNNList(false)"></i>
        </div>
      </h3>
      <div class="what-warp">
        <div class="what-bar">
          <div class="bar-active" :style="{width:barWidth}"></div>
        </div>
        <ul class="what-list" :style="{'margin-left':-1*(nnCurIdx*20.2)+'%'}">
          <a class="what-item" v-for="(item,idx) in nnList" :key="idx"
              :style="{'background-image':'url('+item.imageUrl+')'}"
              :href="item.adjustLink" target="_blank">
            <div class="what-text">
              <h3>{{ item.coverTitle }}</h3>
              <h4>{{ item.activityTime }}</h4>
              <h5 class="diandian">{{ item.activityAddress }}</h5>
            </div>
          </a>
        </ul>
      </div>
    </div>

<!--视频-->
      <video-player
          class="video-player vjs-custom-skin" ref="videoPlayer"
          :options="playerOptions" :playsinline="true"></video-player>
    </div>
  </div>

  <div class="modules w-1200 clear" v-if="isPc">
    <div class="m-news" v-if="news_list.length>0">
      <h3  class="modules-title grey">HOT NEWS <i>热点新闻</i> </h3>
      <ul class="news-list ">
        <router-link tag="li" :to="'/news_detail?id='+item.articleId+'&page=1'"
                     v-for="(item,idx) in news_list"
                     v-if="idx<4"
                     :key="idx">
          <img :src="(GLOBAL.COMMON)().img+item.coverImg" alt="">
          <h3 class="diandian">{{item.title}}</h3>
          <h4 class="diandian3">{{item.summary}}</h4>
          <p>{{item.createTime.split(' ')[0]}}</p>
        </router-link>
      </ul>
    </div>
    <h3 class="modules-title" style="margin-top: 65px;">BUSINESS CLUSTERS<i>产业集群</i> </h3>
    <div class="cluster-module">
        <div class="num-card">
        <span v-for="(num,idx) in clusterList" :key="idx" @mouseover.stop="clusterIndex=idx"
              :class="{active:clusterIndex===idx}">0{{idx+1}}</span>
        </div>

        <div class="text-warp" >
          <a v-for="(item,idx) in clusterList" :key="idx"
               v-show="clusterIndex===idx"
             :href="item.link" target="_blank">
            <h2 class="show-slide">
              <span> {{ item.h2.toUpperCase() }} </span>
            </h2>
            <h3 class="show-fade">{{ item.h3 }}</h3>
            <p class="show-fade">{{item.desc}}</p>
            <button class="show-fade"><span>了解更多</span></button>
          </a>
        </div>

        <div class="img-warp" >
          <div class="clear" v-for="(item,idx) in clusterList" :key="idx"
               :class="cluIndex(idx)">
<!--            <span>{{ item.srcDesc }}</span>-->
            <img :src=item.src :alt="item.srcDesc">
          </div>
        </div>
      </div>

  </div>

  <div class="m-news-2" v-if="!isPc&&news_list.length>0">
    <h3 class="modules-title">HOT NEWS <i>热点新闻</i> </h3>
    <ul>
      <router-link tag="li" :to="'/news_detail?id='+item.articleId" v-for="(item,idx) in news_list" :key="idx" v-if="idx<6" :class="{showFrist:idx==0}">
        <div class="img" v-if="idx==0" :style="{backgroundImage:'url('+(GLOBAL.COMMON)().img+item.coverImg+')'}"></div>
        <h4 class="diandian" v-if="idx!=0">{{item.title}}</h4><em v-if="idx!=0">{{item.createTime.split(' ')[0]}}</em>
        <div class="txt" v-if="idx==0">
          <h5 class="diandian2">{{item.title}}</h5><em>{{item.createTime.split(' ')[0]}}</em>
          <p class="diandian2">{{item.summary}}</p>
        </div>
      </router-link>
    </ul>
  </div>
  <div class="m-news-2" v-if="!isPc">
    <h3 class="modules-title">BUSINESS CLUSTERS<i>产业集群</i> </h3>
    <div class="cluster-module">
      <div class="num-card">
        <span v-for="(num,idx) in clusterList" :key="idx" @click="clusterIndex=idx"
              :class="{active:clusterIndex===idx}">0{{idx+1}}</span>
      </div>

      <div class="text-warp-mobile" >
        <a v-for="(item,idx) in clusterList" :key="idx"
             v-show="clusterIndex===idx"
             :href="item.link" target="_blank">
          <h3 class="show-slide"><span>{{ item.h3 }}</span></h3>
          <p class="show-fade">{{item.desc}}</p>
          <div class="img-warp-mobile show-fade">
            <img :src=item.src :alt="item.srcDesc">
          </div>
        </a>
      </div>
    </div>
  </div>

  </div>
</template>

<script>
import MySwiper from "@/components/my_swiper";
import MySwiperMobile from "@/components/my_swiper_mobile";
import {HomeNews, NnList} from "@/api/index.js";
import cluImage1 from '../../assets/img/2021/cul_1.jpg'
import cluImage2 from '../../assets/img/2021/cul_2.jpg'
import cluImage3 from '../../assets/img/2021/cul_3.jpg'
import cluImage4 from '../../assets/img/2021/cul_4.jpg'
import videoCover from '../../assets/img/2021/video_cover.png'

export default {
  name: "home-index",
  metaInfo: {
    title: "首页"
  },
  components: {
    MySwiper,MySwiperMobile
  },
  data() {
    return {
      isPc:false,
      modulesList: [],
      news_list: [],
      nnList:[],
      nnCurIdx:0,
      sonIdx: null,
      playerOptions: {
        autoplay: false, //如果true,浏览器准备好时开始回放。
        loop: true, // 导致视频一结束就重新开始。
        preload: 'auto',
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [{
          src: 'https://gemdale-sports.oss-cn-shenzhen.aliyuncs.com/static/video/officePV_1080.mp4', // 路径
          type: 'video/mp4' // 类型
        }],
        poster: videoCover,
      },
      clusterIndex:0,
      clusterList:[
        {
          h2:"Competitive Performance Business",
          h3:"竞赛表演业务集群",
          desc:"在竞赛表演方面，弘金地已经形成了高度国际化职业化的赛事矩阵。",
          link:"https://www.gemdalesports.com/sec?fid=3&cid=103",
          src:cluImage1,
          srcDesc:"深圳WTA年终总决赛"
        },
        {
          h2:"Player Training and Management",
          h3:"球员培养与经纪业务集群",
          desc:"在球员业务方面，弘金地从自身的网球优势项目出发，业已形成从培养到经纪的网球运动的职业化链条。",
          link:"https://www.gemdalesports.com/sec?fid=3&cid=104",
          src:cluImage2,
          srcDesc:"职业球员&青少年球员"
        },
        {
          h2:"Sports Assets Management",
          h3:"体育资产经营集群",
          desc:"在体育资产经营方面，弘金地已经形成综合性体育场馆经营、社区园区体育项目活化与经营、商业项目体育主力店经营三大业务类型。",
          link:"https://www.gemdalesports.com/sec?fid=3&cid=105",
          src:cluImage3,
          srcDesc:"深圳坪山综合体"
        },
        {
          h2:" Sports+ operations",
          h3:"体育+运营管理",
          desc:"在体育+运营管理方面，弘金地已经形成体育+教育、体育+酒店的综合运营管理模式。",
          link:"https://www.gemdalesports.com/sec?fid=3&cid=144",
          src:cluImage4,
          srcDesc:"智慧网球"
        }
      ]
    };
  },
  computed:{
    barWidth(){
      if(this.nnList.length<=5){
        return '100%'
      }
      let mine=this.nnList.length-5+1
      return (this.nnCurIdx+1)/mine*100+'%'
    }
  },
  created() {
    this.getData();
  },
  mounted() {
    this.isPc=this.IsPC()
  },
  methods: {
    changeNNList(next=true){
      if(this.nnList.length<=5) return;
      if(next){
        if(this.nnCurIdx===this.nnList.length-5) return;
        this.nnCurIdx+=1
      }else{
        if(this.nnCurIdx===0) return;
        this.nnCurIdx-=1
      }
    },
    IsPC() {
      let userAgentInfo = navigator.userAgent;
      let Agents = ["Android", "iPhone",
        "SymbianOS", "Windows Phone",
        "iPad", "iPod"
      ];
      let flag = true;
      for (let v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false;
          break;
        }
      }
      return flag;
    },
    cluIndex(idx){
      let num=idx-this.clusterIndex
      if(num<0) num=num+this.clusterList.length
      return 'index_'+num
    },
    /*getModules() {
      indexContent(2).then(res => {
        if (res) {
          this.modulesList = res.rows;
        }
      });
    },*/
    getNews() {
      HomeNews({
        pageSize: 6,
        pageNum: 1
      }).then(res => {
        if(res) this.news_list = res.rows;
      });
    },
    getData() {
      // this.getBanner();
      // this.getModules();
      this.getNews();
      this.getNnList()
    },
    getNnList(){
      NnList().then(res=>{
        if(res) this.nnList=res.rows
      })
    },

    toSec(link){
      this.$router.push(link)
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../assets/css/params.less";
em,i{
  font-style: normal;
  font-weight: normal;
}
.show-slide{
  position: relative;
  span{
    opacity: 0;
    animation: slideText .5s .5s forwards;
  }
  &:after{
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background-color: #ea5431;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    animation: slideAction 1s linear forwards;
  }
}
@keyframes slideText {
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
}
@keyframes slideAction {
  0%{
    width: 0;
    left: 0;
  }
  50%{
    width: 100%;
    left: 0;
  }
  100%{
    width: 0;
    left: 100%;
  }
}
.show-fade{
  opacity: 0;
  animation: showFade .5s .5s linear forwards;
}
@keyframes showFade {
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
}

.bg-grey{
  background: #f5f5f5;
  padding: 60px 20px 230px;
}
.saishi-img{
  margin-bottom: 60px;
}
.video-box{
  background: #f5f5f5;
}
.fixBox {
  position: fixed;
  top: 80px;
  right: 20px;
  width: 348px;
  height: 503px;
  z-index: 20001;
  background-image: url("/static/img/activity_icon.png");
  background-size: 100%;
  .cus;
}

.banner2 img {
  position: absolute;
  clip: rect(20px 100px 50px 20px);
}

.banner {
  height: calc(100vh - 75px);
  min-height: 500px;
}
h3.modules-title{
  color: #f9f9f9;
  font-size: 67px;
  font-weight: bold;
  line-height: 54px;
  text-align: center;
  position: relative;
  margin-bottom: 30px;
  &.grey{
    color: #ededed;
  }

  i{
    font-size: 29px;
    color: #221815;
    letter-spacing: 3px;
    position: absolute;
    left: 0;
    top: 0;
    text-align: center;
    width: 100%;
  }
  .change-btns{
    position: absolute;
    height: 40px;
    right: 0;
    bottom: 0;
    width: 114px;
    text-align: center;
    vertical-align: middle;
    i{
      display: block;
      float: right;
      position: initial;
      width: 40px;
      height: 40px;
      background-color: #ededed;
      border-radius: 50%;
      color: #fff;
      text-align: center;
      line-height: 40px;
      margin-left: 14px;
      cursor:pointer;
      transition: color .3s;
      &:hover{
        background-color: #ea5431;
      }
    }
  }
}
.modules {
  margin-top: -230px;
  .m-news{
    padding-top: 65px;
    width: 100%;
    .news-list{
      display: flex;
      justify-content: space-between;
      >li{
        width: 23.5%;
        border: 1px solid #dcdcdc;
        border-radius: 10px;
        box-sizing: border-box;
        overflow: hidden;
        &:last-of-type{
          margin-right: 0;
        }
        img{
          width: 100%;
          height: 176px;
          background-color: #3c1572;
        }
        h3{
          font-weight: 700;
          color: #3c1572;
          line-height: 58px;
          letter-spacing: 1px;
          font-size: 17px;
          padding: 0 20px;
        }
        h4{
          padding: 0 20px;
          font-size: 14px;
          color: #646464;
          line-height: 21px;
          height: 63px;
          overflow: hidden;
        }
        p{
          padding: 22px 20px;
          color: #505050;
          line-height: 21px;
          font-size: 12px;
        }
      }
    }
  }
}


.cluster-module{
  display: flex;
  height: 352px;
  overflow: visible;
  margin-bottom: 120px;
  border-radius: 15px;
  .num-card{
    width: 70px;
    height: 100%;
    >span{
      line-height: 88px;
      text-align: center;
      font-weight: 700;
      color: #b4b4b4;
      font-size: 36px;
      height: 88px;
      display: block;
      background-color: #e6e6e6;
      cursor: pointer;
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;
      transition: all .3s;
      &.active{
        color: #EA5532;
        background-color: #f5f5f5;
      }
    }
  }
  .text-warp{
    padding: 0 60px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #f5f5f5;
    position: relative;
    flex: 1;
    &:after{
      content: "";
      display: block;
      width: 91%;
      height: 100%;
      background-color: #f5f5f5;
      position: absolute;
      right: -20px;
      top: 0;
      z-index:-1;
    }
    h2{
      color: #fddfd8;
      font-size: 26px;
    }
    h3{
      color: #EA5532;
      font-size: 21px;
      font-weight: bold;
      margin-bottom: 30px;
      margin-top: 12px;
    }
    p{
      color: #221815;
      font-size: 13px;
      margin-bottom: 40px;
    }
    button{
      color: #898989;
      font-size: 12px;
      text-align: left;
      font-weight: bold;
      span {
        position: relative;
        display: inline-block;
        &:after {
          content: "";
          display: block;
          width: 22px;
          height: 15px;
          background-image: url("../../assets/img/2021/clu_arrow.png");
          background-size: contain;
          background-repeat: no-repeat;
          right: -85%;
          top: 1px;
          position: absolute;
        }
      }
    }
  }

  .img-warp{
    width: 688px;
    position: relative;
    >div{
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      transition: all .3s;
      //transform-origin: 0 100%;
      &.index_0{
        width: 628px;
        z-index: 4;
        transform: scale(1);
        left: 0;
        //transform: rotate(30deg);
      }
      &.index_1{
        width: 656px;
        z-index: 3;
        transform: scale(0.85);
        left: 44px;
      }
      &.index_2{
        width: 675px;
        z-index: 2;
        transform: scale(0.75);
        left: 80px;
      }
      &.index_3{
        width: 688px;
        z-index: 1;
        transform: scale(0.6);
        left: 137px;
      }
      /*&.active{
        width: 628px;
        z-index: 5;
        transform: scale(1);
        left: 0;
      }*/
    }
    img{
      width: 100%;
      height: 352px;
      border-radius: 15px;
      object-fit: cover;

    }
    span{
      position: absolute;
      top: 23px;
      left: 44px;
      color: #fff;
      font-size: 17px;
      line-height: 20px;
      &:after{
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-left: 14px solid #fff;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        position: absolute;
        left: -26px;
        top: 0;
      }
    }
  }

}

.m-news-2 {
  display: none;
}

@media screen and (max-width: 1024px) {
  .bg-grey{
    padding-bottom: 0;
    padding-top: 16px;
    background: #fff;
  }
  .saishi-img{
    margin-bottom: 20px;
  }
  .video-player{
    border-radius: 5px;
    overflow: hidden;
  }
  .modules {
    display: none;
  }
  h3.modules-title{
    color:#f7f7f7;
    font-weight: bold;
    line-height: 44px;
    text-align: center;
    position: relative;
    margin-bottom: 16px;
    font-size: 30px;

    i{
      font-size: 16px;
      color: #221815;
      position: absolute;
      left: 0;
      top: 0;
      text-align: center;
      width: 100%;

    }
  }
  .m-news-2 {
    display: block;
    margin-top: 0;
    padding: 20px 20px 0;
    .box;
    background: #fff;
    ul{
      border-top: 2px solid #ea5431;
    }
    > ul li {
      width: 100%;
      height: 40px;
      line-height: 40px;
      border-bottom: 1px solid #d2d2d2;
      font-size: 16px;
      display: flex;

      &.showFrist {
        height: 118px;
        .box;
        padding-top: 5px;
        padding-bottom: 5px;

        > .img {
          float: left;
          width: 164px;
          height: 108px;
          background-color: @main1;
          margin-right: 14px;
          background-position: center;
          background-size: cover;
        }

        > .txt {
          flex: 1;
          overflow: hidden;
          line-height: 1.4;

          > h5 {
            color: @main1;
            font-size: 16px;
            height: 43px;
          }

          > em {
            font-size: 12px;
            text-align: left;
            margin-top: 5px;
            margin-bottom: 5px;
          }

          > p {
            font-size: 14px;
            color: #787878;
          }
        }
      }

      h4 {
        font-size: 16px;
        flex: 1;
      }

      em {
        display: block;
        font-size: 12px;
        width: 80px;
        text-align: right;
      }
    }
  }
  .cluster-module{
    flex-direction: column;
    background-color: #f5f5f5;
    height: auto;
    margin-bottom: 50px;
    .num-card{
      width: 100%;
      display: flex;
      height: 26px;
      background-color: #fff;
      span{
        flex: 1;
        font-size: 21px;
        height: 26px;
        line-height: 26px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-left-radius: 0;
      }
    }
    .text-warp-mobile{
      width: 100%;
      padding:20px 8px 8px;
      box-sizing: border-box;
      h3{
        font-size: 12px;
        color: #ea5532;
        margin-bottom: 9px;
        padding-left: 10px;
        display: inline-block;
      }
      p{
        font-size: 9px;
        color: #221815;
        padding-left: 10px;
      }
      .img-warp-mobile{
        border-radius: 5px;
        margin-top: 12px;
        img{
          width: 100%;
          border-radius: 5px;
        }
      }
    }
  }
}
.what-bar{
  width: 100%;
  height: 4px;
  background: #e5e4e4;
  margin-bottom: 25px;
  position: relative;
  .bar-active{
    position: absolute;
    width: 20.2%;
    height: 100%;
    background-color: #ea5532;
    left: 0;
    top: 0;
    transition: width .3s;
  }
}
.what-warp{
  overflow: hidden;
}
.what-list{
  margin-bottom: 100px;
  width: 100%;
  white-space: nowrap;
  transition: margin-left .3s;
  .what-item{
    cursor:pointer;
    background-color: #ea5431;
    width: 19.2%;
    height: 368px;
    margin-left: 1%;
    background-size: cover;
    background-position: center;
    position: relative;
    border-radius: 10px;
    overflow:hidden;
    display: inline-block;
    &:nth-of-type(1){
      margin-left: 0;
    }
    &:hover .what-text{
      height: 50%;
      padding-top: 30px;
      background-color: rgba(96,96,96,0.7);
      h3{
        margin-bottom: 30px;
      }
    }
    .what-text{
      transition: all .3s;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 35%;
      background-color: rgba(96,96,96,0.6);
      font-size: 14px;
      color: #fff;
      padding: 10px 20px;
      box-sizing: border-box;
      white-space:initial;
      line-height: 1.5;
    }
    h3{
      height: 40px;
      margin-bottom: 20px;
      transition: margin-bottom .3s;
    }
    h4,h5{
      padding-left: 20px;
      position: relative;
      line-height: 17px;
      background-size: 15px 17px;
      background-position: left center;
      background-repeat: no-repeat;
      background-image: url("../../assets/img/2021/nn-time@2x.png");
    }
    h5{
      font-size: 12px;
      color: #fff;
      margin-top: 10px;
      background-image: url("../../assets/img/2021/nn-localtion@2x.png");
    }
  }
}

</style>
