<template>
  <div class="my-swiper">
    <el-carousel :height="bannerHeight+'px'" ref="carousel" :interval="4000"
                 :autoplay="play" @change="bannerChange"
                 indicator-position="none" arrow="never">
      <el-carousel-item v-for="(item,idx) in list" :key="idx">
        <div class="img-warp" @mousehover="play=false" @mouseleave="play=true">
          <div class="img" :style="{backgroundImage:'url('+(item.subIdx===-1?item.src:item.subBanner[item.subIdx])+')'}"></div>
          <div class="sub-img-item">
            <div class="img-item" v-for="(subIcon,sIdx) in item.subBannerIcon" :key="sIdx">
              <div class="img" :style="{backgroundImage:'url('+subIcon+')'}" @mouseenter="item.subIdx=sIdx" @mouseout="item.subIdx=-1"></div>
              <p :class="'sub-text-'+idx">{{item.subText[sIdx]}}</p>
            </div>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>

    <button class="arrow arrow-left" @click="changeBanner(true)">
      <i class="el-icon el-icon-back"></i>
    </button>
    <button class="arrow arrow-right" @click="changeBanner(false)">
      <i class="el-icon el-icon-right"></i>
    </button>

    <div class="status-bar">
      <div class="bar" :class="{end:curIdx===list.length-1}">
        <div class="bar-active" :style="{width:(curIdx+1)*60+'px'}"></div>
      </div>
      <span>（{{curIdx+1}}/{{list.length}}）</span>
    </div>
  </div>

</template>

<script>
import banner1 from '@/assets/img/banner/A1.jpg'

import banner21 from '@/assets/img/banner/A2-1.jpg'
import banner22 from '@/assets/img/banner/A2-2.jpg'
import banner23 from '@/assets/img/banner/A2-3.jpg'
import banner24 from '@/assets/img/banner/A2-4.jpg'
import banner22Icon from '@/assets/img/banner/A2-2-icon.png'
import banner23Icon from '@/assets/img/banner/A2-3-icon.png'
import banner24Icon from '@/assets/img/banner/A2-4-icon.png'

import banner3 from '@/assets/img/banner/A3.jpg'

import banner41 from '@/assets/img/banner/A4-1.jpg'
import banner42 from '@/assets/img/banner/A4-2.jpg'
import banner43 from '@/assets/img/banner/A4-3.jpg'
import banner42Icon from '@/assets/img/banner/A4-2-icon.png'
import banner43Icon from '@/assets/img/banner/A4-3-icon.png'

import banner5 from '@/assets/img/banner/A5.jpg'
import banner51 from '@/assets/img/banner/A5.jpg'
import banner51Icon from '@/assets/img/banner/A5-1-icon.png'
import banner6 from '@/assets/img/banner/A6.jpg'
export default {
  components: {
  },
  data: () => ({
    list:[
      { src:banner1,hasSub:false,subIdx:-1,subBanner:null,subText:null,},
      {
        src:banner21,
        hasSub:true,
        subIdx:-1,
        subBanner:[banner22,banner23,banner24],
        subBannerIcon:[banner22Icon,banner23Icon,banner24Icon],/*'中国家庭帆船赛',*/
        subText:['资生堂· 深圳WTA年终总决赛','2021年自由式滑雪及单板滑雪世锦赛','中国家庭帆船赛']
      },
      { src:banner3,hasSub:false,subIdx:-1,subBanner:null,subText:null,},
      {
        src:banner41,
        hasSub:true,
        subIdx:-1,
        subBanner:[banner42,banner43],
        subBannerIcon:[banner42Icon,banner43Icon],
        subText:['南京金地体育公园','金地SE中心-网球学院']/*'深圳平山综合体',*/
      },
      { src:banner5,hasSub:false,subIdx:-1,subBanner:null,subText:null,},
      { src:banner6,hasSub:false,subIdx:-1,subBanner:null,subText:null,},
    ],
    curIdx:0,
    bannerHeight:0,
    play:true
  }),
  watch: {
    // curIdx(){
    //   if(this.curIdx===-1) {
    //     this.curIdx = 1
    //   }
    // }
  },
  beforeDestroy() {
    // clearInterval(this.inter);
    // this.inter = null;
  },
  created() {
    let width=document.documentElement.clientWidth
    this.bannerHeight=750*width/1920
  },
  mounted() {
    // this.init()
  },
  methods: {
    changeBanner(prev=false){

      if(prev){
        /*if(this.curIdx===0) {
          this.curIdx = this.listLen - 1
          // return false
        }else{
          this.curIdx -=1
        }*/
        this.$refs.carousel.prev();
      }else{
        /*if(this.curIdx===this.listLen - 1) {
          this.curIdx = 0
          // return false
        }else{
          this.curIdx +=1
        }*/
        this.$refs.carousel.next();
      }
      // this.$refs.carousel.setActiveItem(this.curIdx)
    },
    bannerChange(num){
      this.curIdx=num
    }
  }
};
</script>

<style lang="less" scoped>
.arrow{
  opacity: 0;
  width: 66px;
  height:66px;
  border-radius: 60%;
  color: #fff;
  border: 2px solid #fff;
  font-size: 34px;
  text-align: center;
  line-height: 66px;
  position: absolute;
  top: 50%;
  margin-top: -80px;
  transition: all .3s;
  z-index: 2;
  transition: all .3s;
  i{
    transition:  all .3s;
  }
  &.arrow-left{
    left: 3%;
    i{
      margin-right: 0;
    }
    &:hover i{
      margin-right: 15px;
    }
  }
  &.arrow-right{
    right: 3%;
    i{
      margin-left: 0;
    }
    &:hover i{
      margin-left: 15px;
    }
  }
  &:hover{
    background-color: #ea5431;
    border-color: #ea5431;
  }
}


.status-bar{
  position: absolute;
  z-index: 2;
  color: #fff;
  font-size: 17px;
  top: 10%;
  left: 0;
  letter-spacing: 2px;
  padding-left: 364px;
  height: 20px;
  line-height: 20px;
  .bar{
    width: 360px;
    background-color: #fff;
    height: 2px;
    position: absolute;
    left: 0;
    top: 11px;
    &:after{
      content: "";
      display: block;
      background-color: #fff;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      position: absolute;
      right: -10px;
      top: -4px;
      transition: all .3s;
    }
    &.end:after{
      background-color: #BFBFBF;
    }
    .bar-active{
      position: absolute;
      height: 100%;
      background-color: #BFBFBF;
      transition: all .3s;
    }
  }
}
.my-swiper{
  position: relative;
  &:hover .arrow{ opacity: 1;}
  .img-warp,.img{
    width: 100%;
    height: 100%;
  }
  .img{
    background-size: cover;
    background-position: center;
    transition: all .3s .3s;
  }
  .show-img{
    opacity: 0;
    transition: all .5s;
    position: absolute;
    left: 0;
    top:0;
    width: 100%;
    height: 100%;
    &.show{
      opacity: 1;
    }
  }
  .sub-img-item{
    cursor: pointer;
    z-index: 2;
    position: absolute;
    bottom: 60px;
    left: 50%;
    margin-left: -600px;
    width: 1200px;
    height: 230px;
    text-align: center;
    .img-item{

      width: 300px;
      display: inline-block;
      .img{
        margin: 24px auto;
        width: 276px;
        height: 162px;
      }
    }
  }
  .img-warp{
    position: relative;
    color: #fff;
    font-size: 25px;

    .sub-text-1{
      color: #333;
    }
    h2,h3{
      position: absolute;
      letter-spacing: 8px;
    }
    &.banner-1{
      h2{
        font-size: 40px;
        top: 500px;
        left: 35%;
        &:after{
          content: "";
          display: block;
          width: 80px;
          height: 2px;
          background-color: #fff;
          position: absolute;
          bottom: -13px;
        }
      }
      h3{
        font-size: 26px;
        top: 580px;
        left: 35%;
      }
    }
    &.banner-2{
      h2{
        top: 320px;
        left: 9.5%;
        font-size: 50px;
        &:after{
          content: '......';
          position: absolute;
          top: 70px;
          width: 100px;
          text-align: center;
          left: 50%;
          margin-left: -50px;
        }
      }
      h3{
        top: 465px;
        left: 19%;
        font-size: 50px;
      }
    }
    &.banner-3{
      h2,h3{
        color: #ea5431;
        font-size: 30px;
      }
      h2{
        top: 334px;
        left: 42%;
      }
      h3{
        top: 694px;
        right: 10%;
      }
    }
    &.banner-4 {
      h2 {
        top: 354px;
        left: 12%;
        &:after{
          border-color: #fff;
        }
      }
    }
    &.banner-5 {
      h2 {
        top: 204px;
        right: 22%;
        &:after{
          width: 20px;
          height: 20px;
          right: -20px;
          left: initial;
          border-color: #fff;
        }
        &:before{
          right: -50px;
          left: initial;
        }
      }
    }
  }
}
@keyframes move {
  from{
    transform: translate(-20px,-20px);
  }
  to{
    transform: translate(0,0);
  }
}
.banner-3,.banner-4,.banner-5{
  h2,h3 {
    font-size: 30px;
    letter-spacing: 2px!important;
    animation: move 3s infinite alternate;

    &:after, &:before {
      content: "";
      display: block;
      width: 44px;
      height: 44px;
      border: 2px solid #ea5431;
      border-radius: 50%;
      position: absolute;
    }
    &:after{
      width: 20px;
      height: 20px;
      top: -20px;
      left: -20px;
    }
    &:before{
      top: -50px;
      left: -50px;
    }
  }
}
</style>
