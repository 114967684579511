<template>
  <div class="my-swiper" :height="bannerHeight+'px'" >
    <el-carousel class="swiper-mobile" :height="bannerHeight+'px'" ref="slideCarousel" :interval="5000"
                 indicator-position="none" arrow="none">
      <el-carousel-item v-for="(item,idx) in list" :key="idx">
        <div class="img" :style='{backgroundImage:"url("+item.src+")"}'></div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import banner1 from '@/assets/img/banner/moblie/A1.jpg'
import banner2 from '@/assets/img/banner/moblie/A2.jpg'
import banner3 from '@/assets/img/banner/moblie/A3.jpg'
import banner4 from '@/assets/img/banner/moblie/A4.jpg'
import banner5 from '@/assets/img/banner/moblie/A5.jpg'
export default {
  components: {
  },
  data: () => ({
    bannerHeight:0,
    curIdx: -1,
    list:[
      { name:"弘金地体育", src:banner1, subTitle:"做中国最有价值的国际化体育企业" },
      { name:"资生堂.深圳WTA年终总决赛", src:banner2, subTitle:"金地集团独家呈现" },
      { name:"", src:banner3, subTitle:"" },
      { name:"", src:banner4, subTitle:"" },
      { name:"", src:banner5, subTitle:"" }
    ]
  }),
  created(){
    let width=document.documentElement.clientWidth
    this.bannerHeight=1000*width/750
  },
  mounted() {
    this.slideBanner()
  },
  methods: {
    // 滑动切换
    slideBanner() {
      //选中的轮播图
      var box = document.querySelector('.swiper-mobile');
      var startPoint = 0;
      var stopPoint = 0;
      //重置坐标
      var resetPoint = function () {
        startPoint = 0;
        stopPoint = 0;
      }
      //手指按下
      box.addEventListener("touchstart", function (e) {
        //手指点击位置的X坐标
        startPoint = e.changedTouches[0].pageX;
      });
      //手指滑动
      box.addEventListener("touchmove", function (e) {
        //手指滑动后终点位置X的坐标
        stopPoint = e.changedTouches[0].pageX;
      });
      //当手指抬起的时候，判断图片滚动离左右的距离
      let that = this
      box.addEventListener("touchend", function (e) {
        // console.log("1：" + startPoint);
        // console.log("2：" + stopPoint);
        if (stopPoint == 0 || startPoint - stopPoint == 0) {
          resetPoint();
          return;
        }
        if (startPoint - stopPoint > 0) {
          resetPoint();
          that.$refs.slideCarousel.next();
          return;
        }
        if (startPoint - stopPoint < 0) {
          resetPoint();
          that.$refs.slideCarousel.prev();
          return;
        }
      });
    },
  }
};
</script>

<style lang="less" scoped>
.my-swiper {
  width: 100%;
}
.img{
  width: 100%;
  height: 100%;
  background-size: cover;
}
</style>
